<template>
    <div class="container mt-5 pt-5 pb-5">
        <b-row class="align-items-center">
            <b-col class="col-12 col-md-8">
                <b-col class="fw600 fs25 genBlueDark">Next Gen Insurance is an independent insurance agency.</b-col>
                <b-col class="col-10">
                    <p>
                        Insurance can be a complicated purchase that requires more than 15 minutes of your time. As an independent insurance agency we work for you, not the insurance company – giving you the power of choice.
                    </p>
                </b-col>
            </b-col>
            
            <b-col class="col-12 col-md-4">
                <b-row class="mx-auto">
                    <b-col class="col-auto"><i class="bi bi-telephone-outbound genBlueDark fs35"></i></b-col>
                    <b-col class="col-auto">
                        (803) 791-0995
                        <br>
                        nextgenins1@gmail.com
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        
        <hr class="pr-0 mr-0 w-100">
        <b-row class="pt-4 mt-3 pl-2">
            <b-col class="col-12 col-md-8" align-h="center">
                <!--About Item -->
                <b-row>
                    <b-col class="col-auto pr-0">
                        <div class="ml-auto">
                            <i class="bi bi-broadcast-pin genBlueDark fs35"></i>
                        </div>
                    </b-col>
                    
                    <b-col class="pl-0 mt-1">
                        <b-col class="fw600 fs25 genBlueDark">We represent many different insurance companies</b-col>
                        <b-col>
                            <p>
                                As an independent insurance agency, we represent many different insurance companies which offer a wide variety of coverage options and price points. With our connections and knowledge of the market, we can often find a better value for your insurance dollar than you might find searching on your own. We do the shopping. You do the saving. In the end you get the right blend of price, coverage, and service.
                            </p>
                        </b-col>
                    </b-col>
                </b-row>
                
                <!--About Item -->
                <b-row>
                    <b-col class="col-auto pr-0">
                        <div class="ml-auto">
                            <i class="bi bi-person-lines-fill genBlueDark fs35"></i>
                        </div>
                    </b-col>
                    
                    <b-col class="pl-0 mt-1">
                        <b-col class="fw600 fs25 genBlueDark">We are licensed insurance advisors</b-col>
                        <b-col>
                            <p>
                                Our licensed insurance advisors know insurance and can explain the complexities of insurance in simple terms, helping you make smart decisions. They make a career out of assessing each client’s unique insurance needs and matching them with the insurance company best equipped to meet those needs at a price that fits the budget.
                            </p>
                        </b-col>
                    </b-col>
                </b-row>
                
                <!--About Item -->
                <b-row>
                    <b-col class="col-auto pr-0">
                        <div class="ml-auto">
                            <i class="bi bi-people-fill genBlueDark fs35"></i>
                        </div>
                    </b-col>
                    
                    <b-col class="pl-0 mt-1">
                        <b-col class="fw600 fs25 genBlueDark">We serve as your personal risk advisor and advocate</b-col>
                        <b-col>
                            <p>
                                Our insurance agents become your personal advisor and advocate, taking the time to listen to you and understand your individual needs. They know it’s not just about finding a price you can afford; it’s also about making certain you are appropriately covered so that you don’t end up insurance-poor if you do suffer a loss. And, if you have a billing or claim concern, or need to change your coverage, they will serve as your advocate, working with the insurance company on your behalf.
                            </p>
                        </b-col>
                    </b-col>
                </b-row>
                
                <!--About Item -->
                <b-row>
                    <b-col class="col-auto pr-0">
                        <div class="ml-auto">
                            <i class="bi bi-shop genBlueDark fs35"></i>
                        </div>
                    </b-col>
                    
                    <b-col class="pl-0 mt-1">
                        <b-col class="fw600 fs25 genBlueDark">We offer one-stop shopping</b-col>
                        <b-col>
                            <p>
                                As an independent insurance agency, we can meet all of your insurance needs with the companies we represent, providing personal, business and employee benefits solutions under one roof. The best insurance for one person may not be the best for another – because everyone’s situation is different. We are committed to help you find the BEST insurance for YOU.
                            </p>
                        </b-col>
                    </b-col>
                </b-row>
                
                <!--About Item -->
                <b-row>
                    <b-col class="col-auto pr-0">
                        <div class="ml-auto">
                            <i class="bi bi-calendar-check genBlueDark fs35"></i>
                        </div>
                    </b-col>
                    
                    <b-col class="pl-0 mt-1">
                        <b-col class="fw600 fs25 genBlueDark">We are by your side for the long haul</b-col>
                        <b-col>
                            <p>
                                Our insurance agents don’t end the relationship when you sign on the dotted line. They periodically review your coverage to ensure you have the right protection in every phase of your life or business. They are there to help you through all the changes in your life, whether you’re going from renting an apartment to buying a home, starting a business, getting married, renovating your home, adding a teen driver to your auto policy, or looking for ways to enhance your employee benefits package.
                            </p>
                        </b-col>
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="col-4 aboutImage d-none d-md-flex"></b-col>
        </b-row>
    </div>
</template>


<script>

export default {
    data() {
        return {
            foods: [
                { value: null, text: "Choose..." },
                { value: "apple", text: "Apple" },
                { value: "orange", text: "Orange" },
            ],
            form: {
                name: null,
                food: null,
            },
        };
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                name: null,
                food: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            alert("Form submitted!");
        },
    },
};
</script>